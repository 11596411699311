.card {
  border: 2px solid rgb(83, 81, 81);
  border-radius: 10px;
  background: linear-gradient(45deg, #010C21 20%, #116B6B);
  z-index: 0;
  /*grid-area: 1 / 1;*/
  margin: 10px;
  height: calc(100% - 32px);
}

.card img,
.card {
  user-select: none;
}

ion-range {
  --bar-background: #7e7e7e;
  --bar-background-active: #fff;
  --bar-height: 8px;
  --bar-border-radius: 8px;
  --knob-background: #fff;
  --knob-size: 20px;
  height: 30px;
}

.mini_bar {
  --bar-background: #7e7e7e;
  --bar-background-active: #fff;
  --bar-height: 2px;
  --bar-border-radius: 8px;
  --knob-background: #fff;
  --knob-size: 0px;
  margin: 0px;
  padding: 0px;
  margin-top: 10px;
  height: 25px;
}
