.deck-container {
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
    padding-top: 10px;
    height: 180px;
    text-overflow: ellipsis;
    // white-space:nowrap;
    overflow: hidden;
}

.center-text {
    position: relative;
    vertical-align: middle;
    text-align: center;
    color: white;
    font-size: auto;
    font-weight: 100;
}

.decks-list {
    height: 100px
}

.decks-swiper {
    width: 100%;
    height: 100%;
    // padding-top: 50px;
    padding-bottom: 10px;
}
  
.decks-swiper-slide {
    // background-position: center;
    // background-size: cover;
    background-color: #7F5AEF;
    // width: 100%;
    height: 90%;
    font-size: 18px;
    color: #fff;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    // padding: 40px 60px;
    border-radius: 0.75em;
}
