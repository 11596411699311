.container {
    display: flex;
    flex-flow: column;
    height: 100%;
    width: 100%;
    margin: 0 auto;
    margin-top:0;
    padding-top:0;
    padding: 0 20px;
  }

.main_deck_container {
    display: flex;
    flex-flow: column;
    width: 90%;
    margin: 0 auto;
    margin-top:0;
    padding-top:0;
}
  
.header {
    display: flex;
    justify-content: space-between;
    flex: 0 1 auto;
    align-items: center;
    padding: 0.75em 0.4em;
    margin: 0;
  }
  
  .header-left {
    width: 100%;
    justify-content: space-between;
    display: flex;
    align-items: center;

  }
  
  .header-right {
    display: flex;
    align-items: center;
  }
  .thinIconBtn{
    color: white;
  }
  
.title {
    margin: 0;
    padding: 0;
    text-align: right;
  }
  
  
/* .card-scroll {
    transition: height 0.25s ease-in-out;
    min-height: var(--card-stack-height);
    margin-top:0;
    padding-top:0;
  } */
  
.content {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    overflow-x: hidden;
  }

.button {
    --border-radius: 12px;
    --background: transparent;
    --box-shadow: none;
    --color: var(--ion-color-primary);
    font-size: 12px;
    padding: 4px 8px;
    padding-top:0;
    margin-top:0;
  }
  
.ripple-effect {
    height: 8px;
    width: 8px;
    margin-top: -4px;
    margin-left: -4px;
    color: var(--ion-color-primary);
  }

.custom-ion-content ::-webkit-scrollbar {
    display: none;
}

.custom-ion-content {
    scrollbar-width: none;
    -ms-overflow-style: none;
}
.categories-container {
  height: min-content;
  justify-content: center;
  padding: 5px 0px;
  width: 100%;
  white-space: nowrap;
}
.category-chips-container{
  overflow-y: hidden;
}


.category-chip {
  background-color: rgb(38, 38, 38);
  color: #000;
  margin-right: 10px;
  margin-bottom: 10px;
  font-size: 1rem;
  border-radius: 20px;
  height: 40px;
  padding: 10px 15px;
  display: inline-flex;
}
.main_deck_container {
  display: flex;
  flex-flow: column;
  width: 90%;
  margin: 0 auto;
  margin-top:0;
  padding-top:0;
  padding-bottom: 1em;
}
.category-chip-text {
  color: #fff;
}
.splash-screen {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: black;
}

.logo {
  width: 200px; /* Adjust the size of the logo as needed */
  height: auto;
}

.company-name {
  margin-top: 20px; /* Adjust the gap between the logo and the company name */
  color: white;
  font-size: 24px; /* Adjust the font size as needed */
  margin-bottom: 2em;
}
.sub-logo {
  position: absolute;
  bottom: 10%;
  margin-top: 2em;
  width: 5em;
  height: auto;
}
.spacer{
  margin: 2em;
}