.container {
  /*align-items: center;*/
  padding: 0 30px;
  margin: 0 auto;
}
.container-profile{
  align-items: center;
  padding: 0 30px;
  margin: 0 auto;
}

.list-item-container {
  display: flex;
  align-items: center;
  width: 80%;
  margin: 20px 0;
}

.empty-box {
  padding: 20px;
  background-color: #94a1b2;
  border-radius: 2px;
}

.text-container {
  display: flex;
  align-items: flex-start;
}

ion-button {
  text-transform: initial;
  padding: 0px;
  margin: 0px;
  color: white;
}
.edit-button {
  color: #F46E6E;
}
ion-item-divider {
  --background: #000000;
}
.genre-selection{
  display: flex;
  overflow-x: scroll;
  height: 50px; /* Base height when dropdown is not visible */
  width: 100%;
  white-space: nowrap;
}
.genre-container{
  width: 100%;
  overflow: hidden;
  
}
.genre-selection.expand{
  height: 200px; /* Increased height when dropdown is visible */
}

.genre-button-container {
  text-align: right;
  color: #7e7e7e;
}

.genre-button {
  color: white;
  background-color: #000000;
  font-size: 1.5em;
  margin-right: 0.5em;
}

.title-plus{
  display: flex;
  width: 100%;
  justify-content: space-between;
}

ion-tab-bar {
  --background: #000000;
}

ion-input {
  --placeholder-color: lightgray;
  --color: white;
  background-color: #161616!important;
  width: 100%!important;
  border-radius: 2em; 
  padding: 10px!important;
  -webkit-padding-start: 10px!important;
  margin-bottom: 1em;
}
ion-select {
  --placeholder-color: lightgray;
  --color: white;
  background-color: #161616!important;
  width: 100%!important;
  border-radius: 2em; 
  padding: 10px!important;
  -webkit-padding-start: 10px!important;
  margin-bottom: 1em;
}
ion-label {
  color: white !important;
  margin-bottom: 0.5em !important;
}

ion-input::part(native) {
  color: white !important;
  padding: 10px!important;
  
}
.input-text{
  padding-left: 10px !important;
  color: #7e7e7e;
}
ion-input::part(native)::placeholder {
  color: white !important;
}

ion-select::part(text) {
  color: white !important;
}

ion-select::part(placeholder) {
  color: white !important;
}

.avatar-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.avatar-container img{
  background: radial-gradient(circle, #9629ae,#7F5AEF);

  border-radius: 50%;
  border: 3px solid transparent;
  -webkit-background-clip: border-box;
  background-clip: border;
  height: 7em;
  width: 7em;
  object-fit: cover;
  margin: 1em;
}
.save-profile{
  color:white;
  width: 100%;
  height: 3em;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #7F5AEF;
  border-radius: 2em;
  margin-top: 1em;
  font-size: 1em;
  margin-bottom: 2em;
  font-weight: bold;
}
.arrowDown{
  display: flex!important;
  justify-content: center!important;
}
.arrowButton ion-button {
  width: 100%;
}

.arrowButton ion-icon {
  margin: auto;
}
.title-name {
  font-size: 2em;
  color: white !important; /* make profile name white */
}
.profile{
  text-align: center;
  color: white;
}
.profile-info{
  display: flex;
  flex-direction: column;
  width: 100%;
}

@media (min-width: 450px) {
    .app-root {
      max-width: 450px;
      margin: auto;
    }

    .scroll-content {
      overflow-y:auto;
    }
}

.custom-ion-content::-webkit-scrollbar {
  display: none!important;
}

.custom-ion-content {
  scrollbar-width: none!important;
  -ms-overflow-style: none!important;
}

.ion-page .ion-content .scroll-content {
  scrollbar-width: none!important;
  -ms-overflow-style: none!important;
}

.ion-page .ion-content .scroll-content::-webkit-scrollbar {
  display: none!important;
}

.dropdown-select {
  color: #7e7e7e;
  background-color: #161616;
  width: 100%;
  border-radius: 2em;
  padding: 10px;
  -webkit-padding-start: 10px;
  margin-bottom: 1em;
  cursor: pointer;
  position: relative;
}
.dropdown-select:hover {
  background-color: #161616;
}

.dropdown-select ul {
  list-style-type: none;
  padding: 20px 0;
  margin: 0;
  position: absolute;
  top: 70%;
  left: 0;
  width: 100%;
  background-color: #161616;
  border-radius: 0 0 2em 2em;
  z-index: 10;
}
.dropdown-select li {
  padding: 10px;
  cursor: pointer;
}
.dropdown-select li:hover {
  background-color: #161616;
}



.dropdown-select-genres {
  color: #7e7e7e;
  background-color: #161616;
  width: calc(100% - 3.7em);
  border-radius: 1.5em;
  position: absolute;
  z-index: 100;
  max-height: 200px;
  overflow-y: auto;
  height: 20em;
  /* The rest of the properties will be inherited from the dropdown-select class */
}

.dropdown-select-genres ul {
  list-style-type: none;
  padding: 20px 0;
  margin: 0;
  position: absolute;
  top: 10%;
  left: 0;
  width: 100%;
  background-color: #161616;
  border-radius: 0 0 2em 2em;
}

.dropdown-select-genres li {
  padding: 10px;
  cursor: pointer;
}


.section-grey {
  margin: auto;
  background-color: #161616;
  width: 90%;
  padding: 20px;
  border-radius: 2em;
  margin-bottom: 5em;
}
.list-item-top {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}

.profile-list-item-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px 0;
}

.list-item-info {
  margin: 0;
}

.progress-bar-container {
  height: 0.5em;
  width: 100%;
  background-color: #444444;
  border-radius: 50px;
  margin: 10px 0;
  overflow: hidden;
}

.progress-bar {
  height: 100%;
  width: 0; /* Initially set to 0 */
  background: linear-gradient(to right,#7F5AEF,purple);
  border-radius: 60px;
}
.analytics-item{
  display: flex;
  flex-direction: row;
  font-size: 1em;
  text-align: left;
  width: 70%;
  justify-content: space-between;
  align-items: center;
  margin: auto;
}
.analytics{
  margin-bottom: 1em;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px 0;
}
.genre-pill {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.75em;
  padding: 1em 2em;
  margin: 5px;
  background-color: #161616;
  color: #7e7e7e;
  border-radius: 20px;
  height: 2em; /* adjust as needed */
  flex-shrink: 0;
}



.spotify-button {
  color:white;
  width: 100%;
  height: 3em;
  font-weight: bold;
  background-color: #1DB954;
  border-radius: 2em;
  margin-top: 1.8em;
  font-size: 1em;
  margin-bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.spotify-button a {
  text-decoration: none;
}
.counter {
  color: white;
  font-size: 1.2em;
}
.title-list{
  color: white;
  text-align: left;
  font-size: 1.2em;
}
.title-analytics-container{
  display: flex;
  align-items: center;
}
.title-analytics {
  color: white;
  text-align: left;
  font-size: 1em;
  margin:0.5em;
}
.subtitle{
  text-align: left;
  font-size: 0.8em;
  color: #7e7e7e;
}
.data{
  font-size: 1.2em;
  font-weight: 550;
  color: white;
}

.placeholder{
  padding-top: 0.75em;
  width: 100%;
  text-align: center;
  color: #7e7e7e;
}
.spacer{
  margin-bottom: 2em;
}