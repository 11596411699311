/* Ionic Variables and Theming. For more info, please see:
http://ionicframework.com/docs/theming/ */

/** Ionic CSS Variables **/
:root {
  /** primary **/
  --ion-color-primary: #3880ff;
  --ion-color-primary-rgb: 56, 128, 255;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #3171e0;
  --ion-color-primary-tint: #4c8dff;
  --ion-color-back: #7f5af0;

  /** secondary **/
  --ion-color-secondary: #3dc2ff;
  --ion-color-secondary-rgb: 61, 194, 255;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #36abe0;
  --ion-color-secondary-tint: #50c8ff;

  /** tertiary **/
  --ion-color-tertiary: #5260ff;
  --ion-color-tertiary-rgb: 82, 96, 255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #4854e0;
  --ion-color-tertiary-tint: #6370ff;

  /** success **/
  --ion-color-success: #2dd36f;
  --ion-color-success-rgb: 45, 211, 111;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #28ba62;
  --ion-color-success-tint: #42d77d;

  /** warning **/
  --ion-color-warning: #ffc409;
  --ion-color-warning-rgb: 255, 196, 9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;

  /** danger **/
  --ion-color-danger: #eb445a;
  --ion-color-danger-rgb: 235, 68, 90;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #cf3c4f;
  --ion-color-danger-tint: #ed576b;

  /** dark **/
  --ion-background-color: #000000;
  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 36, 40;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  /** medium **/
  --ion-color-medium: #92949c;
  --ion-color-medium-rgb: 146, 148, 156;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #808289;
  --ion-color-medium-tint: #9d9fa6;

  /** light **/
  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 245, 248;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;

  /** margin **/
  --ion-margin-top-small: 20px;
  --ion-margin-top-medium: 40px;
  --ion-margin-top-large: 60px;
  --ion-margin-top-extra-large: 80px;

  /** padding **/
  --ion-padding-horizontal-small: 20px;
  --ion-padding-horizontal-medium: 40px;
  --ion-padding-horizontal-large: 60px;
  --ion-padding-horizontal-extra-large: 80px;

  /** fontSizes **/
  --ion-font-small: 20px;
  --ion-font-medium: 24px;
  --ion-font-large: 28px;
  --ion-font-extra-large: 30px;
  --ion-font-family: "Montserrat";
  /** backdrop **/
  --ion-color-backdrop-header-plain: #116B6B;
  --ion-color-backdrop-header-gradient:linear-gradient(45deg, #010C21 20%, #116B6B);
  --ion-color-backdrop-text:#white;
}

.ion-color-back {
  --ion-color-base: var(--ion-color-back);
}

.ion-margin-top-small {
  margin-top: var(--ion-margin-top-small);
}

.ion-margin-top-medium {
  margin-top: var(--ion-margin-top-medium);
}

.ion-margin-bottom-medium {
  margin-bottom: var(--ion-margin-top-medium);
}

.ion-padding-horizontal-small {
  padding: 0 var(--ion-padding-horizontal-small);
}

.ion-padding-horizontal-medium {
  padding: 0 var(--ion-padding-horizontal-medium);
}

.ion-font-small {
  font-size: var(--ion-font-small);
}

.ion-font-medium {
  font-size: var(--ion-font-medium);
}

.ion-font-large {
  font-size: var(--ion-font-large);
}

.ion-font-extra-large {
  font-size: var(--ion-font-extra-large);
}

.ion-heading {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  color: #ffffff;
}

.ion-sub-heading {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 300;
  line-height: 23px;
  color: #fffffe;
}

.ion-para {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
}

.link {
  text-decoration: none;
  position: absolute;
  bottom: 50px;
  right: 10px;
}

.middle {
  background-color: red;
  height: 100vh;
}

.icon-small {
  width: 20px;
  height: 20px;
}

.icon-medium {
  width: 30px;
  height: 30px;
}

.icon-large {
  width: 40px;
  height: 40px;
}

.deck-grid {
  display: grid;
  grid-gap: 5px;
  grid-template-areas:
    ". . . . . . . . . . . ."
    ". . one one one one two two two two . ."
    ". . three three three three two two two two . ."
    ". . three three three three four four four four . ."
    ". . five five five five six six six six . ."
    ". . seven seven seven seven six six six six . ."
    ". . seven seven seven seven eight eight eight eight . ."
    ". . . . . . . . . . . .";

  grid-template-rows: repeat(8, 50px);
  grid-template-columns: repeat(12, "max-content");
}

.deck-grid-item {
  background-color: #16161a;
  padding: 10px 40px;
  border-radius: 8px;
}

.deck-look-header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  line-height: 20px;
}

ion-text, .title {
  font-family: "Montserrat";
}


ion-backdrop {
  opacity: 0.3;
}
ion-tab-bar {
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 999;
}
