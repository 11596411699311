ion-modal {
  --ion-background-color: #000000b9;
}

.modal-container {
  width: 90%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 50px 0;
}

.list-item-container {
  display: flex;
  align-items: center;
  width: 80%;
  margin: 20px 0;
}

.empty-box {
  padding: 20px;
  background-color: #94a1b2;
  border-radius: 2px;
}

.text-container {
  padding-left: 10px;
  display: flex;
  flex-direction: column;
}

.icon {
  width: 60px;
  height: 60px;
  margin: 0 auto;
}

.image {
  width: 60px;
  height: 60px;
  margin: 10px 0;
}

.btn-icon {
  width: 20px;
  height: 20px;
}

@media (max-width: 475px) {
  ion-button {
    margin: 0;
    padding: 0;
  }
}
