.cover-art {
    width: 100%;
    height: auto;
    object-fit:contain;
    position: relative;
    top: -35%;
    align-self: flex-start;
}


.spotify-logo {
    width: 12%;
    height: auto;
    position: absolute;
    top: 85%;
    left: 14%;
}

.heart-icon {
    width: 15%;
    height: auto;
    position: absolute;
    top: 84%;
    right: 14%;
  }
  
  .heart-icon img {
    width: 100%;
    height: 100%;
    fill: red;
    // transition: all 0.5s ease;
  }
  
  .heart-icon img:hover {
    transform: scale(1.2);
  }

.card-title {
    font-size: 1em;
    font-weight: bold;
    text-align: center;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width: 80%;
    margin-top: -40%;
    color: #fff;
    // animation: marquee 10s linear infinite;
}

.card-album{
    font-size: 0.8em;
    text-align: center;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width: 80%;
    margin-top: 2px;
    color: #bdbdbd;
    // animation: marquee 10s linear infinite;
}

.card-artist {
    font-size: 0.75em;
    text-align: center;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width: 80%;
    margin-top: 2px;
    color: #fff;
    // animation: marquee 10s linear infinite;
}


.marquee-wrapper {
    margin-top: -24%;
    display: inline-block;
    animation: marquee 10s linear infinite;
}

@keyframes marquee {
    0% {
        transform: translateX(0%);
    }

    100% {
        transform: translateX(-100%);
    }
}

.icon-container {
    position: absolute;
    top: 82%;
    left: 50%;
    transform: translateX(-50%);
    width: 15%;
    height: 15%;
    display: flex;
    align-items: center;
    justify-content: center;
}