@media (min-width: 390px) {
    .app-root {
      max-width: 390px;
      margin: auto;
      /* position: relative; */
      overflow: hidden;
      scrollbar-width: none;
    }
}

IonPage {
    scrollbar-width: none;
}

body {
  margin: 0;
}