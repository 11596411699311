ion-modal#share-via-copy-modal {
    --background: #242424;
    --width: fit-content;
    --min-width: 300px;
    --height: fit-content;
    --min-height: 100px;
    --border-radius: 15px;
    --border: 20px solid white;
    --box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4);
}

.modal-content {
    min-height: 140px;
    justify-content: center;
    background: black;
    padding: 1em;
    margin:0.15em;
    border-bottom-left-radius: 0.8em;
    border-bottom-right-radius: 0.8em;
    padding-bottom: 0;  
    display: flex; 
    flex-direction: column;
    align-items: center;
}

.url-input{
    padding-left: 10px!important;
}
.copy-url{
    padding-left: 5px!important;
    margin-bottom: 1em!important;
}
.modal-header {
    width: 100%;
    display: flex;
    align-items: center;
    padding-left: 18px;
    margin: 0.15em;
    justify-content: space-between;
    background: #242424;
}

.modal-container {
    width: 90%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 50px 0;
    background: #7e7e7e;
}
.copy-container {
    display: flex;
    width: 100%;
    flex-direction: column;
}
.icon {
    width: 60px;
    height: 60px;
}