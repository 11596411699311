.container {
  padding: 0 30px;
  margin: 0 auto;
}

.search-input {
  border-radius: 10px;
}

.searchbar-input {
  background-color: #fff !important;
  border-radius: 10px !important;
}

.list-item-container {
  display: flex;
  align-items: center;
  width: 80%;
  margin: 20px 0;
}

.empty-box {
  padding: 20px;
  background-color: #94a1b2;
  border-radius: 2px;
}

.text-container {
  padding-left: 10px;
  display: flex;
  flex-direction: column;
}

ion-text {
  margin: 0px;
  padding: 0px;
}

@media (min-width: 450px) {
    .app-root {
      max-width: 450px;
      margin: auto;
      /* position: relative; */
    }
  
    .scroll-content {
      overflow: hidden;
    }
}