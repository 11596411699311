ion-content {
    --background: #000000;
  }

ion-toolbar {
    --background: #111;
  }

ion-back-button {
    --padding-start: 0;
    --padding-end: 0;
    --icon-color: #ffffff;
  }

@media (min-width: 390px) {
    .page-container {
      max-width: 390px;
      margin: auto;
      /* position: relative; */
      overflow: hidden;
      scrollbar-width: none;
    }
}

.spotify-settings{
  font-size: 1.25em;
  color: white!important;
}
  
.settings-item {
    color:#fff;
    padding:10px;
  }

.smaller-label {
    font-size: 0.6em;
    opacity: 0.5;
  }

  .medium-label {
    font-size: 1em;
    opacity: 0.5;
  }

.div-small-label {
    font-size: 0.8em;
    opacity: 0.5;
  }

ion-icon {
    opacity: 0.5;
  }

.settings-item ion-label {
    white-space: normal;
  }

  .settings-item a {
    color: inherit;
    text-decoration: none;
  }
  
  .profile-image {
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 50%;
    overflow: hidden;
  }
  
  .profile-image img {
    width: 100%;
    height: 100%;
  }

  .div-button {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding:20px;
  }

  .sign-out {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    margin:20px;
  }
  .info-container{
    margin: 1em 0;
  }
  .display-name-container {
    display: flex;
    flex-direction: column;
    padding: 5px 20px;
  }

  .likes-title-container {
    width: 70%; /* adjust as needed */
    overflow: hidden;
    white-space: nowrap;
    position: relative;
    padding-right: 1em;
    margin-left: 1em; /* space for ... */
  
  }
  .playlist-header {
    display: flex;
    flex-direction: row;
    background-color: #111;
    align-items: center;
    width: 100%;
    color: #fff;
    padding: 10px 0px 10px 20px;
    overflow: hidden;
  }
  .thumbnail{
    height: 3em!important;
    width: 4em!important;
  }
  .playlist-header h2{
    margin-left: 1em;

  }
  .playlist-container{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: #111;
    margin: 0!important;
  }
  .spotify-label{
    background-color: #111;
    font-size: 1.25em;
    padding: 25px 20px;
  }
  .header-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  
  .spotify-settings {
    text-align: center;
    color: white;
    font-size: 1.45em;
  }
  
  .unlink-spotify{
    color:white;
    width: 80%;
    height: 3em;
    font-weight: bold;
    background-color: #1DB954;
    border-radius: 2em;
    margin: auto;
    margin-top: 1.8em;
    font-size: 1em;
    margin-bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .spacer{
    margin-bottom: 1em;
  }