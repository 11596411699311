ion-content {
    --background: #000000;
  }

ion-toolbar {
    --background: #000000;
  }

ion-back-button {
    --padding-start: 0;
    --padding-end: 0;
    --icon-color: #ffffff;
  }
ion-title{
    padding-left: 0!important;
}
@media (min-width: 390px) {
    .page-container {
      max-width: 390px;
      margin: auto;
      /* position: relative; */
      overflow: hidden;
      scrollbar-width: none;
    }
}
.spotify-playlist {
    margin: 1.5em 1em;
    color: #7e7e7e;
    background-color: #161616;
    padding-left: 1em!important;
    width: auto;
    border-radius: 2em;
    padding: 10px;
    -webkit-padding-start: 10px;
    margin-bottom: 1em;
    cursor: pointer;
    position: relative;
  }
  
  .spotify-playlist:hover {
    background-color: #161616;
  }
  
  .spotify-playlist ul {
    list-style-type: none;
    padding: 7px 0;
    margin: 0;
    position: absolute;
    top: 70%;
    left: 0;
    width: 100%;
    background-color: #161616;
    border-radius: 0 0 2em 2em;
    z-index: 10;
  }
  
  .spotify-playlist li {
    padding: 0.75em 1em;
    cursor: pointer;
  }
  
  .spotify-playlist li:hover {
    background-color: #161616;
  }
  
ion-label {
    width: 100%!important;
}
.or{
    margin: 1em 0;
    font-size: 2em;
    text-align: center;
    color: #7F5AEF;
}
.settings-item {
    color:#fff;
    padding:10px;
  }

.smaller-label {
    font-size: 0.6em;
    opacity: 0.5;
  }

  .medium-label {
    font-size: 1em;
    opacity: 0.5;
  }

.div-small-label {
    font-size: 0.8em;
    opacity: 0.5;
  }

ion-icon {
    opacity: 0.5;
  }

.settings-item ion-label {
    white-space: normal;
  }

  .settings-item a {
    color: inherit;
    text-decoration: none;
  }
  
  .profile-image {
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 50%;
    overflow: hidden;
  }
  
  .profile-image img {
    width: 100%;
    height: 100%;
  }

  .div-button {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding:20px;
  }

  .sign-out {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    margin:20px;
  }

.create-playlist-container{
    margin: 1.5em 1em 2em 1em;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.create-playlist-button{
    font-size: .9em;
    border-radius: 2em;
    margin-left: 1em;
    padding-bottom: 0;
}
.add-to-playlist-container{
  background-color: #000000;
    margin: 1em 0;
}
.connect-spotify-container{
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}
.confirm-button {
    margin-top: 2em!important;
    margin-bottom: 1em!important;
    padding: 1em;
    background-color: #7F5AEF;
    border-radius: 2em;
    color:white;
    text-align: center;
    width: 80%;
    margin: auto;
}
.playlist-name-input {
    padding-left: 1em!important;
    color:white;
}

.disabled {
    pointer-events: none;
}
.header-container{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

}
.header-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.back-button{
    margin-left: 0.2em;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 2em;
    color: white!important;
}
.add-to-spotify{
    margin-left: 0.5em;
    font-size: 1.5em!important;
    color: #fff;
    text-align: center!important;
}