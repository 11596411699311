.onboarding-page-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 100%!important;
    padding: 0 10px;
}
.next-button{
    display: flex;
    justify-content: center;
    align-items: center;
    color:white;
    width: 70%;
    height: 3em;
    background-color: #7F5AEF;
    border-radius: 2em;
    margin-bottom: 2em;
}
.img-container{
    padding: 10px;
    width: 50vh;
}
.onboarding-info-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;  
}
.subtitles{
    color: #7e7e7e!important;
}
