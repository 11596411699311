
.container-tab{
    padding: 0 30px;
    margin: 0 auto;
}

ion-tab-bar{
    background-color: black!important;
    border:black solid 5px;
    border-bottom: 1em solid black;
    border-radius: 2em!important;
    border-bottom-right-radius: 0!important;
    border-bottom-left-radius: 0!important;
    display: flex;
    align-self: center;
    flex-direction: row;
    justify-content: center;
    max-width: 50%;
}
ion-tab-button{
    background-color: #141414;
    --background-focused: #7F5AEF!important;
    --color-selected: white!important;
    --ion-color-base: white!important;
    ion-icon{
        font-size: 2.5em;
    }
    position: relative;
    
}
ion-tab-button:first-child {

    border-top-left-radius: 20em;
    border-bottom-left-radius: 20em;
    padding-right: 1em;
}

ion-tab-button:last-child {

    border-top-right-radius: 20em;
    border-bottom-right-radius: 20em;
    padding-left: 1em;
}
.custom-ion-content ::-webkit-scrollbar {
    display: none;
}

.custom-ion-content {
    scrollbar-width: none;
    -ms-overflow-style: none;
}
@media (max-width: 500px) {
    ion-tab-bar{
        max-width: 88%;
    }

}
.active-tab-line {
    height: 4px;
    border-radius: 2em;
    background-color: #7F5AEF;
    width: 30%;
    position: absolute;
    bottom:0.5em;
}

