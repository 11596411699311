.favorited-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(12, 1fr);
  grid-gap: 15px;
}

@media (min-width: 390px) {
    .app-root {
      max-width: 390px;
      margin: auto;
      /* position: relative; */
      overflow: hidden;
      scrollbar-width: none;
    }
}