.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  pointer-events: auto;
  background-color: rgba(0, 0, 0, 0.5);
  flex-direction: column;
}

.overlay-content {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

}

.mute-icon {
  font-size: 48px;
  color: black;
  margin-right: 10px; /* Add margin to create space between icon and text */
}

.overlay-text {
  margin-top: 2px;
  font-size: 16px;
  color: black;
  text-align: left; /* Align the text to the left */
}
