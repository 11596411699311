.container {
  padding: 0 20px;
}

.header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.liked-item-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 20px 0;
}

.empty-box {
  padding: 20px;
  background-color: #94a1b2;
  border-radius: 2px;
}

.text-container {
  display: flex;
  align-items: flex-start;
}

@media (min-width: 390px) {
    .app-root {
      max-width: 390px;
      margin: auto;
      /* position: relative; */
      overflow: hidden;
      scrollbar-width: none;
    }
}