.header-toolbar {
    --background: #000000;
  }
  
  .header-logo {
    --min-height: 24px;
    height: 100% !important;
    padding: 0 !important;
    left: 0;
  }
  
  .header-logo img{
    color: #7F5AEF;
    width: 3em !important;
    height: 3em !important;  
  }
  
  .header-icon {
    --min-width: 44px;
    width: 100% !important;
    height: 100% !important;
    padding: 0!important;
  }
  .header-icon ion-icon {
    color: #666666;
    width: 1.5em !important;
    height: 1.5em !important;    
  }

  .title {
    padding-bottom: 0.2em !important;
    font-size: 1.5em!important;
    color: #fff;
    text-align: left;
  }
  
  .toolbar {
    --background: #000000;
    width: 100%!important;
  }
  
  .header-app {
    width: 100% !important;
    margin: 0 auto!important;
  }

  @media (min-width: 390px) {
    .header-app {
      margin: auto;
      /* position: relative; */
      overflow: hidden;
      scrollbar-width: none;
    }
}

.flex-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.left-section {
  display: flex;
  align-items: center;
}

.title {
  margin-left: 10px;
}