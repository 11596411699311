.swiper {
    width: 80%;
    height: 95%;
}

.card-swiper-slide {
    display: flex;
    flex-direction: column;
    border-radius: 1.25em;
    background-color: rgb(38, 38, 38);
    position: relative;
    overflow: hidden; /* Hide any content that goes outside the bounds of the element */
    &:before {
        content: "";
        position: absolute;
        top: -10%;
        left: -10%;
        right: -10%;
        bottom: -10%;
        background-size:100%;
        z-index: -1;
    }
}

.loading-cards {
    font-size: 1em;
    font-weight: 40px;
    text-align: center;
    // text-overflow: ellipsis;
    // white-space: nowrap;
    // overflow: hidden;
    width: 80%;
    // margin-top: -24%;
    // line-height: 1.5;
    color: #fff;
    // animation: marquee 10s linear infinite;
}

// .card-swiper-slide:nth-child(1n) {
//     &:before {
//         background-image: url('../../assets/card-background/light-blue.jpeg');
//     }
// }

// .card-swiper-slide:nth-child(2n) {
//     &:before {
//         background-image: url('../../assets/card-background/burgundy.jpeg');
//     }
// }

// .card-swiper-slide:nth-child(3n) {
//     &:before {
//         background-image: url('../../assets/card-background/charcoal.jpeg');
//     }
// }

// .card-swiper-slide:nth-child(4n) {
//     &:before {
//         background-image: url('../../assets/card-background/deep-green.jpeg');
//     }
// }

// .card-swiper-slide:nth-child(5n) {
//     &:before {
//         background-image: url('../../assets/card-background/deep-purple.jpeg');
//     }
// }

.red-heart-toast .toast-icon {
    color: red;
  }

ion-toast {
    --ion-color-random: #FBD1A2;
  }


.random-color-toast {
    --background: var(--ion-color-random);
    --color: white;

    
  }
      