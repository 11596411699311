
.likes-image-container {
  width: 4em;
  height: 4em;
}

.image-wrapper {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  overflow: hidden;
}

.circle-thumbnail {
  display: block;
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: auto;
  object-fit: cover;
  /* adjust as needed */
}

.likes-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /*background-color: #111;*/
  color: #fff;
  padding: 15px 20px;
  overflow: hidden;
}

.likes-title-container {
  width: 70%; /* adjust as needed */
  overflow: hidden;
  white-space: nowrap;
  position: relative;
  padding-right: 1em;
  margin-left: 1em; /* space for ... */

}


.likes-title {
  font-size: 17px!important;
  position: relative;
  top: 0;
  left: 0;
  white-space: nowrap;
}


.recently-liked-section {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.recently-liked-title{
  padding: .5em 0em;
  color: white;
  text-align: center;
  font-weight: bold;
  height: 1em;
}
.recently-played-container {
  margin-top: 1em!important;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  overflow-x: scroll;
  /*background-color: #111;*/
  max-width: 390px;
  color: #fff;
  padding: 10px;
  -ms-overflow-style: none;  /* Internet Explorer and Edge */
  scrollbar-width: none;  /* Firefox */
  font-family: "Montserrat";
}

.recently-played-container::-webkit-scrollbar { 
  display: none;  /* Chrome, Safari and Opera */
}

ion-label h3{
  font-size: .75em!important;
  color:#7e7e7e;
}


.song-card {
  flex-shrink: 0;
  width: 160px;
  height: 160px;
  margin-right: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  flex: 0 0 auto;
  overflow: hidden;
}
.song-card img {
  width: 100%;
  height: auto;
  border-radius: 1em;
  padding: .5em;
}

.songs-title-container {
  width: 90%; /* adjust as needed */
  overflow: hidden;
  white-space: nowrap;
  position: relative;
  padding: 0em 0em; /* space for ... */
}

.song-card-title{
  width: 100%;
  font-size: 15px;
  color: white;
  text-align: center;
  margin-top: 0;
  margin-bottom: 0;
  white-space: nowrap;
}

.song-title-scrolling {
  animation: scrollingText 10s linear infinite;
}
.song-card-album {
  overflow: hidden;
  white-space: nowrap;
  font-size: 10px;
  width: 100%;
  color: grey;
  text-align: center;
  margin-top: .6em;
}

.no-border {
  --border-width: 0!important;
}


@keyframes scrollingText {
  0% { transform: translateX(0); }
  50% { transform: translateX(-20%); }
  100% { transform: translateX(0); }
}


.likes-header .likes-title {
  font-size: 14px;
}

.likes-header .likes-share {
  display: flex;
  align-items: center;
  font-size: 8px;
}

.likes-header .likes-share .likes-share-icon {
  color: #61dafb;
  font-size: 28px;
  margin-right: 5px;
}

.likes-header .likes-subtitle {
  height: 1.5em;
  line-height: 1em;
  font-size: 12px;
  visibility: hidden;
  margin-top: .5em;
}
.delete-button {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 1.5em;
  line-height: 1em;
  font-size: 12px;
  text-align: center;
  margin-top: .5em;
  margin-right: 1em;
  color: #ffffff;
  background-color: #f46e6e;
  border-radius: 2em;
  padding: 0 0.7em;
}

.likes-header .likes-subtitle-collapsed {
  visibility: visible;
  height: 1.5em;
  line-height: 1em;
  opacity: 0.7;
  font-size: 12px;
  margin-top: .5em;
}
.title-container{
  width: 100%;
  display: flex;
  background-color: #0F0F0F;
  max-width: 390px;
  margin: auto;
}
.saved-playlists{
  color: white;
  padding: 1em;
  padding-top: 1.5em;
  font-size: 20px;
}



.likes-icons {
  display: flex;
  align-items: center;
}

.likes-icons ion-icon {
  font-size: 28px;
  margin-left: 10px;
}

.song-info {
   color:#fff;
}


h2, h3 {
  color: #fff;
}

.thumbnail-size {
  width: 2.4em;
  height: 2.4em;
}


ion-loading.loading_class {
    --background: #FBD1A2;
    --spinner-color: #030303;
  
    color: #1c6dff;
}
.custom-ion-content::-webkit-scrollbar {
  display: none;
}

.custom-ion-content {
  scrollbar-width: none;
  -ms-overflow-style: none;
}
.page-container {
  background-color: #0F0F0F;
}

.liked-song-icon {
  font-size: 1.5em;
  margin-right: 0.25em;
}


.likes-deck-buttons{
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: left;
}

@media (min-width: 390px) {
    .page-container {
      max-width: 390px;
      margin: auto;
      /* position: relative; */
      overflow: hidden;
      scrollbar-width: none;
    }
    .recently-played-container{
      max-width: 390px;
      margin: auto;
    }
    .custom-ion-content::-webkit-scrollbar {
      display: none;
    }
    
    .custom-ion-content {
      scrollbar-width: none;
      -ms-overflow-style: none;
    }

}
.spacer{
  margin: 2.5em;
}